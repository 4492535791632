export default theme => ({
  root: {
    position: 'relative',
    marginBottom: 15,
  },

  fullWidthFormControl: {
    marginBottom: 0,
  },

  menuListDropdown: {
    minWidth: 500,
    position: 'absolute',
    maxHeight: '50vh',
    overflow: 'auto',
    zIndex: '100',
    boxShadow: `0 1px 4px 0 ${theme.palette.CHANGEME[41]}`,
  },

  menuListAbove: {
    top: '-50vh',
    height: '50vh',
  },

  fullWidthMenu: {
    width: '100%',
    maxHeight: '40vh',
    marginTop: -2,
  },

  header: {
    fontWeight: 700,
    color: theme.palette.text.primary,
    cursor: 'default',
    pointerEvents: 'none',
    backgroundColor: theme.palette.text.light,
  },

  options: {
    '& div': {
      paddingLeft: 20,
    },
  },
  formControl: {
    marginBottom: 0,
  },
});
