import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { isEmpty, isNil, path, concat } from 'ramda';
import cn from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import {
  MenuItem,
  MenuList,
  InputAdornment,
  InputLabel,
  FormHelperText,
  FormControl,
  ClickAwayListener,
  Input as MUIInput,
  OutlinedInput as MUIOutlinedInput,
} from '@material-ui/core';

import Icon from 'components/Icon';
import Loader from 'components/Loader';
import Paper from 'components/Paper';
import InfiniteScroll from 'components/InfiniteScroll';

import styles from './styles';

class AutocompleteAsync extends Component {
  static propTypes = {
    classes: PropTypes.shape().isRequired,
    getOptions: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
    startIcon: PropTypes.string,
    endIcon: PropTypes.string,
    placeholder: PropTypes.string,
    dataNode: PropTypes.string,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    inputLabel: PropTypes.string,
    labelPath: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.string,
    helperText: PropTypes.string,
    error: PropTypes.bool,
    fullWidthMenu: PropTypes.bool,
    menuAbove: PropTypes.bool,
    saveValue: PropTypes.bool,
    showMissingOption: PropTypes.bool,
    onSelectMissingOption: PropTypes.func,
    disableUnderline: PropTypes.bool,
    startIconClassName: PropTypes.string,
    endIconClassName: PropTypes.string,
    className: PropTypes.string,
    getAdditionalOptions: PropTypes.func,
    hasInfiniteScroll: PropTypes.bool,
    outlined: PropTypes.bool,
  };

  static defaultProps = {
    labelPath: ['name'],
  };

  constructor(props) {
    super(props);

    this.state = {
      searchTerm: props.value || '',
      options: [],
      loading: false,
      open: false,
      meta: {},
      isLoadingMore: false,
    };
    this.handleSearch = debounce(this.handleSearch, 500);
    this.infiniteScroll = React.createRef();
    this.scrollParent = React.createRef();
  }

  handleSetScrollRef = ref => {
    this.infiniteScroll.current = ref;
  };

  setParentRef = ref => {
    this.scrollParent.current = ref;
  };

  getOptionName = option => {
    const { labelPath } = this.props;
    return path(labelPath, option);
  };

  handleClose = () => {
    const { value } = this.props;
    this.setState({ open: false, searchTerm: value || '' });
  };

  handleFocus = () => {
    const { searchTerm } = this.state;
    if (!isEmpty(searchTerm)) {
      this.setState({ open: true, searchTerm: '' });
    }
    this.handleSearch();
  };

  handleBlur = () => {
    const { value } = this.props;
    const { searchTerm } = this.state;

    if (isNil(value) || searchTerm.trim() !== value.trim()) {
      this.setState({ searchTerm: '' });
    }
  };

  handleChangeInput = event => {
    const searchTerm = event.target.value;
    this.setState({ searchTerm, searchedValue: searchTerm });
    this.handleSearch();
  };

  handleSearch = () => {
    const { getOptions } = this.props;
    const { searchTerm } = this.state;
    this.setState({ loading: true, open: true });

    if (!isNil(this.infiniteScroll.current)) {
      this.infiniteScroll.current.pageLoaded = 1;
    }

    getOptions(searchTerm).then(response => {
      const {
        options = response,
        meta = { currentPage: 1, numPages: 1 }, // assumes single page if no meta was received
      } = response;

      this.setState({
        loading: false,
        options: this.handleOptions(options, meta),
        meta,
      });
    });
  };

  handleOptions = (options, meta) => {
    const { showMissingOption } = this.props;
    const { searchTerm } = this.state;

    return showMissingOption && meta.currentPage >= meta.numPages
      ? this.addMissingOption(options, searchTerm)
      : options;
  };

  loadMoreOptions = (page = 1) => {
    const { getOptions, getAdditionalOptions } = this.props;
    const { searchTerm } = this.state;
    const loadMore = getAdditionalOptions || getOptions;

    this.setState({ isLoadingMore: true });

    return loadMore(searchTerm, page).then(({ meta, options }) => {
      return this.setState({
        isLoadingMore: false,
        meta,
        options: this.handleOptions(concat(this.state.options, options), meta),
      });
    });
  };

  addMissingOption = (options, searchTerm) => [
    ...options,
    {
      value: 'missing',
      name: `Can't find ${searchTerm || "what you're looking for"}? Click here to notify our support team.`,
    },
  ];

  handleAdd = option => () => {
    const { saveValue } = this.props;
    const { searchedValue } = this.state;

    if (option.value === 'missing') {
      this.props.onSelectMissingOption(searchedValue);
      this.setState({ searchedValue: '' });
      return this.handleClose();
    }

    if (saveValue) {
      this.setState({ searchTerm: this.getOptionName(option) }, this.handleClose);
    }

    return this.props.onAdd(option);
  };

  renderSimpleOption = option => (
    <MenuItem key={option.id} onClick={this.handleAdd(option)} data-node="autocomplete-menu-item" button>
      <div>{this.getOptionName(option)}</div>
    </MenuItem>
  );

  renderHeadOption = head => {
    const { classes } = this.props;
    if (!isNil(head.options)) {
      return (
        <>
          <MenuItem className={classes.header}>
            <div>{this.getOptionName(head)}</div>
          </MenuItem>
          <div className={classes.options}>{this.renderOptions(head.options)}</div>
        </>
      );
    }
    return this.renderSimpleOption(head);
  };

  renderOptions = options => {
    const [head, ...tail] = options;

    return (
      <>
        {!isNil(head) && !isEmpty(head.options) && this.renderHeadOption(head)}
        {!isEmpty(tail) && this.renderOptions(tail)}
      </>
    );
  };

  renderData = () => {
    const { loading, options } = this.state;
    if (loading) return <Loader />;
    if (isEmpty(options)) return <MenuItem>No search result</MenuItem>;
    return <MenuList disablePadding>{this.renderOptions(options)}</MenuList>;
  };

  renderInfiniteScroll = () => {
    const { loading, isLoadingMore, meta } = this.state;
    return (
      <InfiniteScroll
        loadMore={this.loadMoreOptions}
        setRef={this.handleSetScrollRef}
        initialLoad={false}
        useWindow={false}
        meta={meta}
        loading={loading || isLoadingMore}
        getScrollParent={() => this.scrollParent.current}
      >
        {this.renderData()}

        {isLoadingMore && !loading && <Loader />}
      </InfiniteScroll>
    );
  };

  renderOptionsDropdown = () => {
    const { classes, fullWidthMenu, menuAbove, hasInfiniteScroll } = this.props;
    return (
      <Paper
        className={cn(classes.menuListDropdown, {
          [classes.fullWidthMenu]: fullWidthMenu,
          [classes.menuListAbove]: menuAbove,
        })}
        setRef={this.setParentRef}
      >
        {hasInfiniteScroll ? this.renderInfiniteScroll() : this.renderData()}
      </Paper>
    );
  };

  renderInput = () => {
    const { searchTerm } = this.state;
    const {
      classes,
      placeholder,
      dataNode,
      inputLabel,
      id,
      startIcon,
      endIcon,
      disabled,
      helperText,
      error,
      fullWidthMenu,
      disableUnderline,
      startIconClassName,
      endIconClassName,
      outlined,
    } = this.props;

    const Input = outlined ? MUIOutlinedInput : MUIInput;

    return (
      <FormControl
        className={cn(classes.formControl, { [classes.fullWidthFormControl]: fullWidthMenu })}
        error={!!error}
        fullWidth
        data-node={dataNode}
      >
        <InputLabel htmlFor={id}>{inputLabel}</InputLabel>
        <Input
          inputRef={ref => {
            this.input = ref;
          }}
          startAdornment={
            startIcon && (
              <InputAdornment position="start">
                <Icon icon={startIcon} className={cn(startIconClassName)} />
              </InputAdornment>
            )
          }
          endAdornment={
            endIcon && (
              <div onClick={!disabled && this.handleFocus} role="presentation">
                <InputAdornment position="end">
                  <Icon icon={endIcon} className={cn(endIconClassName)} />
                </InputAdornment>
              </div>
            )
          }
          onChange={this.handleChangeInput}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          disabled={disabled}
          id={id}
          placeholder={placeholder}
          value={searchTerm}
          disableUnderline={disableUnderline}
          variant="outlined"
        />
        {helperText && <FormHelperText id={`${id}-helper-text`}>{helperText}</FormHelperText>}
      </FormControl>
    );
  };

  render = () => {
    const { open } = this.state;
    const { classes, className } = this.props;
    const rootStyles = cn([classes.root], className);

    return (
      <ClickAwayListener onClickAway={this.handleClose}>
        <div className={rootStyles}>
          {this.renderInput()}
          {open && this.renderOptionsDropdown()}
        </div>
      </ClickAwayListener>
    );
  };
}
export default withStyles(styles)(AutocompleteAsync);
